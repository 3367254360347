import { ThemeOptions, createTheme } from '@mui/material';
import colors, {
  darkPrimary,
  darkSecondary,
  // darkTertiary,
  lightPrimary,
  lightSecondary,
  // lighterTertiary,
} from './colors';

export const muiThemeConfig = {
  typography: {
    fontFamily: [].join(','),
  },
  palette: {
    primary: {
      main: colors.primary,
      dark: darkPrimary,
      light: lightPrimary,
      contrastText: colors.white,
    },
    secondary: {
      main: colors.secondary,
      dark: darkSecondary,
      light: lightSecondary,
      contrastText: colors.white,
    },
    info: {
      main: colors.light,
      // dark: darkTertiary,
      // light: lighterTertiary,
      contrastText: colors.dark,
    },
    warning: {
      main: colors.warning,
    },
  },
};

export const muiDarkThemeConfig: ThemeOptions = {
  ...muiThemeConfig,
  palette: {
    ...muiThemeConfig.palette,
    mode: 'dark',
  },
};

export const muiTheme = createTheme(muiThemeConfig);

export const darkMuiTheme = createTheme(muiDarkThemeConfig);
