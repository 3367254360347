'use client';

import { PropsWithChildren, ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { muiTheme } from '#/lib/theme';

type Props = PropsWithChildren & {
  nav?: ReactNode;
  sidebar?: ReactNode;
};

export default function OffSznShell({ children }: Props) {
  return (
    <MuiThemeProvider theme={muiTheme}>
      <IntlProvider locale="en">
        {children}
        <div id="modal" />
      </IntlProvider>
    </MuiThemeProvider>
  );
}
