'use client';

import Container from '../components/Container';
import { MEDIA_QUERY_MOBILE } from 'bouqei';
import Link from 'next/link';
import colors, { darkerPrimary } from '../lib/colors';
import { rowStyles } from '../components/Flex';
import styled, { keyframes } from 'styled-components';
import zIndex from '../lib/zIndex';
import { useMemo } from 'react';
import useScrollTop from '../hooks/useScrollTop';
import Image from 'next/image';

// const navLinks = [
//   {
//     text: 'Home',
//     href: '/',
//     disabled: false,
//   },
//   {
//     text: 'Posts',
//     href: 'https://www.patreon.com/BadlandsTOJ/posts',
//     target: '_blank',
//   },
//   {
//     text: 'Membership',
//     href: 'https://www.patreon.com/BadlandsTOJ/membership',
//     target: '_blank',
//   },
//   {
//     text: 'Store',
//     href: 'https://badlands.sellfy.store/',
//     target: '_blank',
//   },
// ];

// type Props = {
//   customNavLinks?: typeof navLinks;
// };

const Header = () => {
  const { scrollTop } = useScrollTop();
  // const [showMobileMenu, toggleMobileMenu] = useState(false);

  const addFill = useMemo(() => {
    return scrollTop > 0;
  }, [scrollTop]);

  // const closeMobileMenu = () => {
  //   toggleMobileMenu(false);
  // };

  // const links = useMemo(
  //   () =>
  //     customNavLinks.map(({ href, text, target }) => (
  //       <Link key={text} href={href} target={target} onClick={closeMobileMenu}>
  //         {text}
  //       </Link>
  //     )),
  //   [customNavLinks]
  // );

  return (
    <Wrapper
      style={{ backgroundColor: addFill ? colors.primary : 'transparent' }}
    >
      <Container className="menu-container" $contained>
        {/* <MobileToggle onClick={() => toggleMobileMenu(!showMobileMenu)}>
          <Button format="light" fab $transparent>
            <i className={showMobileMenu ? 'fas fa-times' : 'fas fa-bars'} />
          </Button>
        </MobileToggle> */}
        <Link className="brand-link" href="/">
          <Brand>
            <Image
              src="/images/badlands_outline.png"
              width="1680"
              height="721"
              alt="TOJ Badlands"
            />
          </Brand>
        </Link>
        {/* <Flex
          className={`links ${showMobileMenu ? 'show' : ''}`}
          alignItems="center"
          style={{ flex: 1 }}
        >
          <LinkContainer gap="32px" justifyContent="flex-start">
            {links}
          </LinkContainer>
        </Flex> */}
      </Container>
    </Wrapper>
  );
};

export default Header;

export const HEADER_HEIGHT = '75px';

const menuAnimation = keyframes`
  0% {
    opacity: 0;
    max-height: 0;
  }
  100% {
    opacity: 1;
    max-height: 2000px;
  }
`;

const Wrapper = styled.header`
  width: 100%;
  height: ${HEADER_HEIGHT};
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${zIndex.Header};
  transition: background-color 0.5s ease-in-out;

  .menu-container {
    ${rowStyles('center', 'center')}
    max-width: 1280px;
    width: 100%;

    h1 {
      font-size: 2rem;
      text-transform: uppercase;
      letter-spacing: 0.15em;
      text-shadow: 1px 0px 1px ${colors.dark};
    }
  }

  &.fill {
    background-color: ${colors.primary};
  }

  @media ${MEDIA_QUERY_MOBILE} {
    .menu-container {
      ${rowStyles('flex-start', 'center')} /* padding-left: 0; */
      /* padding: 0 calc(5% + 40px) 0 5%; */
    }
    .brand-link {
      flex: 1;
      width: 100%;
    }
    .links {
      position: fixed;
      top: ${HEADER_HEIGHT};
      left: 0;
      width: 100%;
      height: calc(100vh - ${HEADER_HEIGHT});
      background-color: ${darkerPrimary};
      display: none;

      &.show {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        animation: ${menuAnimation} 0.25s ease-in-out;
        transform-origin: top left;
      }
    }
  }
`;

const Brand = styled.div`
  flex: 1;
  height: ${HEADER_HEIGHT};
  position: relative;
  padding: 0;
  text-align: center;
  img {
    width: auto;
    height: 100%;
  }
`;

// const LinkContainer = styled(Flex)`
//   /* flex: 1; */
//   a {
//     color: ${colors.white};
//     font-size: 1.15rem;
//     font-weight: 500;
//     min-width: 50px;
//     text-align: left;

//     &.disabled {
//       pointer-events: none;
//       opacity: 0.75;
//     }
//   }

//   a.mobile-only {
//     display: none;
//   }
//   /* flex: 1; */

//   /* @media ${MEDIA_QUERY_LARGE} {
//     display: none !important;
//   }*/

//   @media ${MEDIA_QUERY_MOBILE} {
//     flex-direction: column;
//     align-items: center;
//     width: 100%;
//     padding: 32px 0 0;

//     a {
//       text-align: center;
//       font-size: 1.5rem;
//     }

//     a.mobile-only {
//       display: inline-block;
//     }
//   }
// `;

// const MobileToggle = styled.div`
//   display: none;

//   i {
//     font-size: 1.3rem;
//   }

//   @media ${MEDIA_QUERY_MOBILE} {
//     display: block;
//   }
// `;
